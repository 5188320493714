<script src="http://code.angularjs.org/1.2.9/angular-animate.min.js"></script>
<section class="bg" [hidden]="notLogged">
  <div class="navbar">
    <img class="navlogo1" src="assets/images/finel logo1.png" />
    <img class="navlogo2" src="assets/images/addv1.jpg" />
  
    <div class="dropdown">

    </div>
    <div class="dropdown">

    </div>

    <div class="dropdown">
      <button class="dropbtn">Seller </button>
      <div class="dropdown-content">
        <a >Post a Property</a>
        <a href="/login">Seller Login</a>
        <a >Register as Seller</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn" >Agents</button>
    </div>
    <div class="dropdown">
      <button class="dropbtn" >Google Ads</button>
    </div>

    <div class="dropdown">
      <button class="dropbtn">Login/Register </button>
      <div class="dropdown-content">
        <a >Login</a>
        <a >Register</a>
      </div>
    </div>

    <div class="dropdown">
      <div class="btn2">

        <i class="fas fa-eye" aria-hidden="true"></i>

      </div>
    </div>

  </div>

</section>
<section class="bg" [hidden]="loggedIn">
  <div class="navbar">
    <strong>Postprop.in</strong>

    <div class="dropdown">
      <button class="dropbtn">Post Property </button>

    </div>
    <div class="dropdown">
      <button class="dropbtn" >Check Profile</button>
    </div>
    <div class="dropdown" [hidden]="admin">
      <button class="dropbtn">Admin Rights</button>
      <div class="dropdown-content">
        <a >Maintain Property</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn">Log Out </button>
      <div class="dropdown-content">
        <a (click)="logout()">Logout</a>

      </div>
    </div>


  </div>

</section>

<div><img src="src\assets\images\addv1.jpg" alt=""></div>

