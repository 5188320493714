import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// import { DataService } from '../services/data.service';
// import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/loginService';
import { Login } from '../models/login';


import { ReportFilter, Globle, ErrorCode } from '../Globle';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  hideClient: boolean = false;



  constructor(private loginService: LoginService, private route: Router) { }
  ngOnInit() {

    this.loginForm = new FormGroup({
      loginId: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      caCode: new FormControl(null, Validators.required),
      clientCode: new FormControl(null, Validators.required),
      roleName: new FormControl(null, Validators.required)

    })

  }
  invalidData = true;
  myPayload
  loginResponse
  async userLogin() {
    if (this.roleName != null) {
      this.loginForm.patchValue({
        roleName: this.roleName

      })
      console.log("rolename", this.loginForm);



      console.log(this.loginForm.value, "form vl");
      this.loginService.userLogin(this.loginForm.value).subscribe(
        data => {
          this.loginResponse = data;
          console.log(this.loginResponse, "response");
          if (this.loginResponse.length > 0 && this.loginResponse[0].status != false) {
            Globle.user = this.loginResponse;
            Globle.userType = this.loginResponse[0].userType;
            Globle.loggedIn = true;
            console.log("this is the globle user", Globle.user[0].userType, "*****", this.loginResponse[0].userType,
              "this is the name");

            sessionStorage.setItem('payload', JSON.stringify(this.loginResponse));

            if (this.roleName == "CA") {
              sessionStorage.setItem('payload', JSON.stringify(this.loginResponse));
              console.log("redirect to ca");

              this.route.navigate(['/pages/masters/dashboard']);

            }
            else {
              sessionStorage.setItem('payload', JSON.stringify(this.loginResponse));
              console.log("redirect to client");

              this.route.navigate(['/pages/masters/client-upload']);
            }
          }
          else {
            this.invalidData = false;
            this.loginForm = new FormGroup({
              loginId: new FormControl(null, Validators.required),
              password: new FormControl(null, Validators.required),
              caCode: new FormControl(null, Validators.required),
              clientCode: new FormControl(null, Validators.required),
              roleName: new FormControl(null, Validators.required)

            })
          }
        }
      )
    } else {
      alert("Please Select Your Role First")
    }
  }


  goToForgot(){
    sessionStorage.clear();
    this.route.navigate(['/pages/masters/forgot-password'])
  }

  Register() {

    sessionStorage.clear();
    this.route.navigate(['/pages/masters/user-registration']);


  }

  roleName
  display(event) {

    console.log(event.target.value);
    this.roleName = event.target.value;

    if (this.roleName == "CA") {


      this.hideClient = true;
    }
    else if (this.roleName == "Client") {


      this.hideClient = false;
    }
    //  this.loginService.selectUser( this.loginForm.value,this.roleName).subscribe(
    //    data =>{
    //      var resp = data;
    //      ErrorCode.getErrorCode(resp) ,error => ErrorCode.getErrorCode(error)

    //    }
    //  )

  }

 

}

