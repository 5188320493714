import { Component, OnInit ,ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Globle, ErrorCode } from '../Globle';
import { FormGroup, FormControl } from '@angular/forms';
import { UserDetails } from '../models/userDetails';


@Component({
  selector: 'ngx-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  filter: FormGroup;
  constructor(private route: Router,  private sanitizer: DomSanitizer
    , private el :ElementRef) {
  }
  others = false;
  loggedIn = true;
  user
  admin = true;
  ngOnInit(): void {

 console.log(this.el.nativeElement);
 
    this.payload = JSON.parse(sessionStorage.getItem('payload'));

   
    console.log(Globle.user, "user");

    this.findUser();
  
    this.filter = new FormGroup({
      cost: new FormControl(null),
      typeOfProperty: new FormControl(""),
      propertyAddress: new FormControl(null),
      pinCode: new FormControl(null),

    })
    // Globle.loggedIn = false;
    console.log(this.notLogged, "ot logged", this.loggedIn, "logged in", Globle.user);


    console.log(Globle.user, "globle user");


  }


  ngAfterContentInit() {
    console.log("checked");
    setTimeout(() => {
      console.log('works')

    }, 10000)
  }
  payload

  homeSlider = { items: 1, dots: true, nav: true }
    ;
  changeImage() {
    for (let i = 0; i >= 0; i++) {
      setTimeout(() => {
        console.log('works')

      }, 200)
    }
  }

 

  
 
  notLogged

  images
  image
  imageList = []
  // dispplayImage = new PropertyImages();
  item = this.imageList[0];
  imageData = [];
  
  goTOCheckProfile() {
    this.route.navigate(['/pages/masters/check-profile']);
  }

  logout() {
    this.notLogged = false;
    this.loggedIn = true;
    Globle.userType = "";
    sessionStorage.clear();

    
    this.route.navigate(['/login']);

    // this.route.navigate
  }

 
  

 

  openProperty() {
    console.log("in openProperty");

  }
 
  
 


  /// function for logged in user or not logged in user

  findUser() {
    if (Globle.loggedIn) {
      console.log(this.payload, "payload", this.payload[0].userType);
      this.loggedIn = false;
      // if(this.payload[0].userType=="Agent"){
      //   if(this.CheckAgentProfile())
      //   {    
      //    this.goToRegisterAgent();
      //     console.log(this.payload);
      //   }
      // }


      // this.user=Globle.user.name;
      this.notLogged = true;
      if (this.payload[0].userType == "admin") {
        this.admin = false;
        this.others = true;
      }
    }
  }
  resp;
  // CheckAgentProfile(){
  //   let emailId =this.payload[0].emailId;
  //   let agentName = this.payload[0].name;
  //   console.log("going for checking profile");

  // this.agentServices.CheckAgentProfile(emailId,agentName).subscribe(
  //  data =>{
  //    this.resp=data;
  //    ErrorCode.getErrorCode(this.resp), error => ErrorCode.getErrorCode(error)
  //    console.log("this is the response " + this.resp);

  //  }
  // )
  // return this.resp;

  // }

}

