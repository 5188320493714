import { HttpClient, HttpHeaders } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Userlogin } from "../models/userlogin";
import { environment } from "../../environments/environment";



@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserloginService
{
  constructor(private http: HttpClient) { }
  private reqHeader = new HttpHeaders({
    "Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Methods":"GET, POST",
    "Content-Type":"application/json",
});

  private baseUrl = `${environment.apiUrl}/tms/userlogin`;

  createUserlogin (userlogin: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}` + `/create`, userlogin, { headers: this.reqHeader ,observe: 'response'});
  }

 
  updateUserlogin (userlogin: Object): Observable<Object> {
    return this.http.put(`${this.baseUrl}` + `/update`, userlogin, { headers: this.reqHeader,observe: 'response'});
  }

 
  
  

  getLogedInUsers(companyId) {
    return this.http.get<Userlogin[]>(`${environment.apiUrl}/tms/userlogin/findlogedin/${companyId}`);
    }


 
  getLoginByCompanyCode(companyCode){
    return this.http.get<Userlogin[]>(`${environment.apiUrl}/tms/userlogin/findAll/${companyCode}`);
  }
  allCompany(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  searchLogin(companyCode,branchId,empCode){
    return this.http.get(`${environment.apiUrl}/tms/userlogin/searchLogin/${companyCode}/${branchId}/${empCode}`);
  }

  userLogOut(loginId) {
    return this.http.get(`${environment.apiUrl}/tms/userlogin/logout/` + loginId ).pipe()
  }
  getCompanyLogo(companyId){
    return this.http.get(`${environment.apiUrl}/tms/company/companylogo/` + companyId)
  }

}