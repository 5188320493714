import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Login } from "../models/login";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class LoginService {
  
 
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/powerExchanger/userLogin`;

  private reqHeader = new HttpHeaders({
    "Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Methods":"GET, POST",
    "Content-Type":"application/json",
});

  // userLogin(companyId, userId, pwd) {
  //   return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/findlogin/` + companyId + `/` + userId + `/` + pwd).pipe(map(res => res))
  // }
// userLogin ( loginForm )
// {
//   return this.http.post(`${this.baseUrl}/userLogin`,loginForm,{ headers: this.reqHeader});
// }

userLogin ( loginForm)
{
  return this.http.post(`${this.baseUrl}/userLogin`,loginForm);
}

  searchLoginId(term) {
    return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/finduser/` + term ,{ headers: this.reqHeader}).pipe(map(res => res))
  }

  getForms(reportFilter: Object): Observable<Object> {
    var obj=this.http.post(`${environment.apiUrl}/tms/formauthentication/getforms`, reportFilter,{ headers: this.reqHeader});
        return obj;
  }
  registration(register: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/userlogin/register`, register,{ headers: this.reqHeader,observe : 'response'});
  }
  registrationWithFile(register: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/userlogin/registerWithFile`, register,{headers: this.reqHeader,observe : 'response'});
  }
  genrateOTP(email){
    return this.http.get(`${environment.apiUrl}/tms/userlogin/genrateOtp/`+email,{ headers: this.reqHeader})
  }
  sms(register){
    // const httpOptions = {
    //   headers: new HttpHeaders({ 
    //     'Access-Control-Allow-Origin':'*',
    //     'Authorization':'authkey',
    //     // 'userid':'1'
    //   })
    // };httpOptions
    // return this.http.post(`http://localhost:7071/fams/sendSms/smsPost1`, register);
    return this.http.post('http://biz.sms4power.com/Api/smsapi/JsonPost',register,{ headers: this.reqHeader})
  }
  getEmail(emailId){
    return this.http.get(`${environment.apiUrl}/powerExchanger/userLogin/getEmail/${emailId}`)
  }
  getContactNo(contactNo : string){
    return this.http.get<String[]>(`${environment.apiUrl}/tms/userlogin/getContactNo/`+contactNo,{ headers: this.reqHeader})
  }
  fetchOpenYear(){
    return this.http.get(`${environment.apiUrl}/powerExchanger/sessionYear/fetchOpenYear`);
}
fetchClients(caCode) {
  return this.http.get(`${this.baseUrl}/fetchClients/${caCode}`);
}

updatePassword ( loginForm:Object)
{
  return this.http.post(`${this.baseUrl}/updatePassword`,loginForm,{observe:"response"});
}

// selectUser (loginForm,roleName)
// {
//   return this.http.post(`${this.baseUrl}/selectUser`,loginForm,roleName);
// }
 


findAllCA() {
  return this.http.get(`${this.baseUrl}/findAllCA`);
}
}


