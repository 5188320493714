<section class="bg">
  <div class="navbar">
      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline"></nb-icon>
        </a>

    <!-- <button class="btn" (click)="goHome()">Home</button> -->
    <h4 class="heading"><mark>DIGI</mark>POWER </h4>
    <div class="header-container">
        
      <nb-actions size="small">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
          <!-- <nb-user [nbContextMenu]="userMenu"
                   [onlyPicture]="userPictureOnly"
                   name="Developer"
                   >
          </nb-user> -->
          <nb-user [nbContextMenu]="userMenu"
              [onlyPicture]="userPictureOnly"
              [name]="userName"
              >
          </nb-user>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img [src]="img" width="50px" height="50px" *ngIf="img"/>
        </nb-action>
      </nb-actions>
    </div>
    </div>
   
    
</section>

